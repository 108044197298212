export default {
  data () {
    return {
      valid: false,
      login: '',
      rules: [
        value => {
          if (!value) {
            return 'Поле обязательно'
          }
          const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          this.valid = pattern.test(value)
          return pattern.test(value) || 'Неверный email'
        }
      ]
    }
  },
}