<template>
  <div class="login d-flex flex-column b-card">
    <div
      role="button"
      @click="$router.push('login')"
      class="align-self-baseline pl-1 pt-1 links"
    >
      <v-icon color="#64C7FF">mdi-arrow-left-thin</v-icon>
      назад
    </div>
    <div class="pa-5 pt-3">
      <v-card-title class="pa-0 pb-2">Регистрация</v-card-title>

      <Input
        @change="fullName = $event"
        :placeholder="$t('login.fullName')"
        width="330px"
        class="mb-3"
        required
      />
      <Input
        width="330px"
        :placeholder="$t('login.login')"
        @change="login = $event"
        :rules="rules"
        class="mb-4"
        id="login"
        required
      />
      <Checkbox
        v-model="entity"
        text="являюсь юридическим лицом"
      />
      <div class="d-flex justify-center mt-5">
        <Button
          color="white"
          background-color="#64C7FF"
          @click="registration"
          width="250px"
          :disabled="!valid"
          class="mb-3"
        >
          <template #content>
            {{ $t('login.registration') }}
          </template>
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/base/UI/Button'
import Input from '@/components/base/UI/Input'
import common from '@/views/public/common'
import Checkbox from '@/components/base/UI/Checkbox'

export default {
  name: 'Registration',
  components: { Checkbox, Button, Input },
  mixins: [common],
  data () {
    return {
      entity: false
    }
  },
  methods: {
    registration () {

    }
  }
}
</script>
