<template>
  <label class="checkbox style-c">
    <input
      @change="onChange"
      :value="value"
      type="checkbox"
    />
    <div class="checkbox__body">{{ text }}</div>
    <div class="checkbox__checkmark"></div>
  </label>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    text: String,
    value: Boolean
  },
  methods: {
    onChange () {
      this.$emit('input', !this.value)
    }
  }
}
</script>

<style
  scoped
  lang="scss"
>
  .checkbox.style-c {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .checkbox.style-c input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkbox.style-c input:checked ~ .checkbox__checkmark {
    background-color: $light-breeze;
  }

  .checkbox.style-c input:checked ~ .checkbox__checkmark:after {
    opacity: 1;
  }

  .checkbox.style-c:hover input ~ .checkbox__checkmark {
    background-color: #eee;
  }

  .checkbox.style-c:hover input:checked ~ .checkbox__checkmark {
    background-color: $light-breeze;
  }

  .checkbox.style-c .checkbox__checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    transition: background-color 0.25s ease;
    border-radius: 4px;
  }

  .checkbox.style-c .checkbox__checkmark:after {
    content: "";
    position: absolute;
    left: 8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
    transition: opacity 0.25s ease;
  }

  .checkbox.style-c .checkbox__body {
    line-height: 1.4;
    font-size: 16px;
  }

  input:checked + .checkbox__body {
    color: $light-breeze;
  }
</style>